/* eslint-disable prettier/prettier */
<template>
  <v-container fluid class="d-flex flex-column">
    <v-layout child-flex>
      <v-card class="pa-2 d-flex flex-column justify-space-between">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('Search')" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="tableBridges" :items-per-page="15" :search="search" :sort-desc="false">
          <template v-for="header in nonSlotHeaders" v-slot:[`item.${header.value}`]="{ item }">
            <LinkToResource :key="header.value" :item="item">{{ item[header.value] }}</LinkToResource>
          </template>
          <template v-slot:[`item.direction`]="{ item }">
            <div v-if="item.direction">
              <LinkToResource :item="item">{{
                item.direction
                ? $store.state.sysCode.sysCodes.find(
                  (x) => x.code == item.direction
                )[description]
                : ""
              }}</LinkToResource>
            </div>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="d-flex align-center">{{ $t('Bridges list') }}
                <DataExport :data="tableBridges" :headers="exportHeaders" file-name="Bridges" />
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn v-if="$store.getters.get_access('module_bridge_management', 3)" :to="{
                name: 'bridge-edit-identification-data',
                params: { id: -1, bridge: Object },
              }
                " color="green" dark>{{ $t('New bridge') }}</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <GoToBridge :id="item.id" />
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
    <v-layout child-flex>
      <v-card class="pa-2 d-flex flex-column justify-space-between">
        <v-card-title>
          {{ $t('export') }}
        </v-card-title>
        <v-list class="ma-4">
          <v-list-item >{{ $t('Bridges raw data') }} <DataExportFull :get-data="getBridgesFullExport" file-name="Bridges" /></v-list-item>
          <v-list-item >{{ $t('Elements raw data') }} <DataExportFull :get-data="getElementsFullExport" file-name="Elements" /></v-list-item>
        </v-list>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import GoToBridge from "@/components/main/GoToBridge.vue";
import LinkToResource from "@/components/main/LinkToResource.vue";
import router from "@/router";
import DataExportFull from "@/components/main/DataExportFull.vue";
import { flattenSysCode } from "@/helpers"
import BridgeService from "@/services/BridgeService";

export default {
  name: "bridge-list",
  components: {
    GoToBridge,
    LinkToResource,
    DataExportFull,
  },
  data() {
    return {
      search: "",
    };
  },
  props: ["bridges"],
  computed: {
    headers() {
      return [
        {
          text: this.$t("header-road"),
          value: "roadnumber",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("header-link"),
          value: "linkid",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("header-chainage"),
          value: "chainage",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("header-direction"),
          value: "direction",
          sortby: true,
          align: "left",
        },
        {
          text: this.$t("header-bridgename"),
          value: "bridge_name",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("header-rivername"),
          value: "river_name",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("header-province"),
          value: "province_name",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("header-district"),
          value: "district_name",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("header-bridgelength"),
          value: "bridge_length",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("header-nospan"),
          value: "nb_of_spans",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("data-status"),
          value: "inventory_data_status",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("hi"),
          value: "hi",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("hi-quality"),
          value: "hi_data_quality",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: "",
          value: "actions",
          sortby: false,
          color: "blue",
          align: "left",
        },
      ];
    },
    exportHeaders() {
      const displayedHeaders = [...this.headers]
      displayedHeaders.pop();

      const additionalHeaders = ['bridge_gps_x', 'bridge_gps_y', 'id', 'bridge_material'].map(h => ({ text: this.$t(h), value: h }))
      return displayedHeaders.concat(additionalHeaders)
    },
    tableBridges() {
      return this.bridges.map(bridge => ({
        ...bridge,
        province_name: bridge.bridge_province ? this.$store.state.adm.provincesList.find(
          (x) => x.id == bridge.bridge_province
        )[this.description] : '',
        district_name: bridge.bridge_district ? this.$store.state.adm.districtList.find(
          (x) => x.id == bridge.bridge_district
        )[this.description] : ''
      }));
    },
    nonSlotHeaders() {
      return this.headers.filter(x => x.slot === false);
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
  },
  methods: {
    showInventoryDetail(item) {
      router.push({
        name: "bridge-inventory",
        params: { id: item.id },
      });
    },
    async getBridgesFullExport() {
      return (await BridgeService.getAll())
        .data;
    },
    async getElementsFullExport() {
      const elements = (await BridgeService.getAllElements())
        .data;

      return elements.map(x => ({
        ...x,
        ...x.Bridge,
        ...flattenSysCode("SysComponent", x),
        ...flattenSysCode("SysElement", x),
        "SysComponent": x.SysComponent.code,
        "SysElement": x.SysElement.code,
        "Bridge": x.Bridge.chainage
      }))
    }
  }
};
</script>
