/* eslint-disable prettier/prettier */
<template>
  <v-container fluid class="d-flex flex-column">
    <v-layout child-flex>
      <v-card class="pa-2 d-flex flex-column justify-space-between">
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('Search')" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="inspectionsList" :search="search" :items-per-page="15"
          :sort-by="['inspection_year', 'inspection_type']" :sort-desc="[true, false]" multi-sort
          class="elevation-1 outlined">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('INSPECTION LIST') }}
                <DataExport :data="inspectionsList" :headers="exportHeaders" file-name="inspections"/>
                </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="showInspectionDetail(item)">
                  mdi-note-text-outline
                </v-icon>
              </template>
              <span>{{ $t('Details') }}</span>
            </v-tooltip>
          </template>
          <!-- </template> -->
        </v-data-table>
      </v-card>
    </v-layout>
    <v-layout child-flex>
      <v-card class="pa-2 d-flex flex-column justify-space-between">
        <v-card-title>
          {{ $t('export') }}
        </v-card-title>
        <v-list class="ma-4">
          <v-list-item >{{ $t('Damages raw data') }} <DataExportFull :get-data="getDamagesFullExport" file-name="damages" /></v-list-item>
        </v-list>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import InspectionService from "@/services/InspectionService";
import router from "@/router";
import DataExportFull from "@/components/main/DataExportFull.vue";

export default {
  name: "InspectionsList",
  components: {
    DataExportFull
  },
  data() {
    return {
      search: "",
      inspectionsList: [],
    };
  },
  computed: {
    bridgeId() {
      return this.$route.params.id;
    },
    headers() {
      return [
        {
          text: this.$t("header-road"),
          value: "roadnumber",
          sortby: true,
          align: "center",
          slot: false,
        },
        {
          text: this.$t("header-link"),
          value: "linkid",
          sortby: true,
          align: "center",
          slot: false,
        },
        {
          text: this.$t("header-chainage"),
          value: "chainage",
          sortby: true,
          align: "center",
          slot: false,
        },
        {
          text: this.$t("header-direction"),
          value: "direction",
          sortby: true,
          align: "center",
        },
        {
          text: this.$t("header-bridgename"),
          value: "bridge_name",
          sortby: true,
          align: "left",
          slot: false,
        },
        {
          text: this.$t("year-insp"),
          value: "inspection_year",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("type-insp"),
          value: "inspection_type",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("name-insp"),
          value: "inspector_name",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("date-insp"),
          value: "inspection_date",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("inspection_data_status"),
          value: `InspectionDataStatus.${this.description}`,
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("hi"),
          value: "hi",
          sortby: true,
          color: "blue",
          align: "center",
        },
        { text: "", value: "actions", color: "blue", align: "center" },
      ];
    },
    exportHeaders() {
      return this.headers.slice(0, this.headers.length - 1)
    },
    nonSlotHeaders() {
      return this.headers.filter(x => x.slot === false);
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
  },
  async mounted() {
    this.inspectionsList = (await InspectionService.index()).data
  },
  methods: {
    async getDamagesFullExport() {
      const elements = (await InspectionService.getAllDamages())
        .data;

      return elements
    },
    async showInspectionDetail(item) {
      console.log(item.id)
      router.push({
        name: "bridge-inspection-details",
        params: { inspectionId: item.id, id: item.bridge_id },
      });
    },
  }
};
</script>
